import React from "react"

import Layout from "../components/layout"
import { useLocation } from '@reach/router'
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 auto;
  margin: 1rem 0 2rem;
  padding: 0 1rem;
  align-self: center;
  max-width: 50rem;
  color:var(--dark-grey);

`

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  color:var(--secondary-high);



@media (min-width: 992px) {
  font-size: 3rem;
}
`
const StyledP = styled.p`
margin:0;
`
const StyledH2 = styled.h2`
color:var(--secondary-high);
font-size:1.5rem;
@media (min-width: 992px) {
  font-size: 2rem;
}
`


const AppIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  margin: 1rem 0 0;
  width:100%;
`

const StoreBadge = styled.div`
  margin: 1rem 1rem;
`

const ImageWrapper = styled.div`
margin: 1rem 0;
display:flex;
justify-content:center;
align-items:center;
width:100%;
`


export default function Page() {
  const data = useStaticQuery(graphql`
    query {
      Google: file(
        name: { eq: "google-play-badge" }
        extension: { eq: "png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 60
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      iOS_Badge: file(name: { eq: "apple_badge" }, extension: { eq: "png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 60
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      Setup: file(relativePath: { eq: "Setup.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const location = useLocation();
  let country ="DE"
  
  const url = typeof window !=="undefined" ? window.location.href :"";


  if(url.includes(".ch")){
    country ="CH"
  }

  
  return (
    <Layout location={country}>
      <Wrapper>
        <StyledH1>Willkommen bei DoryGo</StyledH1>
        <StyledP>
          {" "}
          Vielen Dank, dass Sie sich für den DoryGo Service entschieden haben!{" "}
          <br />
          <br />
          Es sind nur noch wenige Schritte notwendig um Ihren Service
          vollständig einzurichten:
        </StyledP>
        <StyledH2>1. App installieren</StyledH2>
        <StyledP>
          Laden Sie unsere DoryGo App herunter und melden Sie sich mit Ihrem
          Passwort an.
        </StyledP>
        <AppIcon>
          <a
            href="https://play.google.com/store/apps/details?id=com.doryhealth.app"
            target="_blank"
            rel="noreferrer noopener"
          >
            <StoreBadge>
              <GatsbyImage
                image={data.Google.childImageSharp.gatsbyImageData}
                alt="Download App on Android"
              />
            </StoreBadge>
          </a>
          <a
            href="https://apps.apple.com/us/app/dorygo-einfach-bequem/id1569657963"
            target="_blank"
            rel="noreferrer noopener"
          >
            <StoreBadge>
              <GatsbyImage
                image={data.iOS_Badge.childImageSharp.gatsbyImageData}
                alt="Download App on iOS"
              />
            </StoreBadge>
          </a>
        </AppIcon>
        <StyledH2>2. Service konfigurieren</StyledH2>
        <StyledP>Klicken Sie auf "App einrichten" und folgen Sie den Anweisungen.</StyledP>
        <ImageWrapper>
        <GatsbyImage
                image={data.Setup.childImageSharp.gatsbyImageData}
                alt="App einrichten"
              />
        </ImageWrapper>


        <StyledP>
        Lehnen Sie sich zurück und genießen Sie die neue Freiheit, <br/><br/>
Ihr DoryGo Team

</StyledP>
      </Wrapper>
    </Layout>
  )
}
